import React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  Box,
  Head,
  Title,
  Modal,
  Gallery,
  IOExample,
} from '../components';

export default ({ data }) => (
  <Layout>
    <Box>
      <Title tag="span" size="large">
        {data.articlesJson.content.childMarkdownRemark.rawMarkdownBody}
      </Title>
    </Box>
    <Gallery items={data.articlesJson.gallery} />
  </Layout>
);

export const query = graphql`
  query ArticlesQuery {
    articlesJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
